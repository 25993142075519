import './App.css';
import FileUploader from './FileUploader';

function App() {
  return (
    <> 
    <FileUploader/>
      
    </>
  );
}

export default App;
