

import React, { useState, useEffect } from 'react';
import './App.css';
import { Button, Col, Form, Row } from 'react-bootstrap';

const FileUploader = () => {
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({ data: {} });
  const [uploadFileMsg, setUploadFileMsg] = useState("");
  const [csvRecordTotal, setCsvRecordTotal] = useState();
  const [status, setStatus] = useState(true);

  const baseUrl = "https://ship.havfly.com";
  // const baseUrl = "http://localhost:5000";
  // if (window.location.host) {
  //   baseUrl = window.location.protocol + "//" + window.location.host;
  // }  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/allrecord/list`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setCsvRecordTotal(jsonData?.record);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [status]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('csv', file);
      try {
        const response = await fetch(`${baseUrl}/api/shiprocket/file/upload`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          setUploadedFiles(data); // Assuming the API response is an object
          setUploadFileMsg(data)
          console.log('File upload successful:', data);
        } else {
          console.error('Error uploading file:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error uploading file:', error.message);
      }
    } else {
      console.error('No file selected');
    }
  };
  // const keysAndValues = uploadedFiles.data
  //   ? Object.entries(uploadedFiles.data).map(([timestamp, value]) => ({
  //     key: timestamp,
  //     productName: value.productName.join(' , '),
  //     orderid: value.orderid.join(', '),
  //   }))
  //   : [];

  return (
    <div className='container mt-5'>
      <Row>
        <Col md={6}>
          <h2>Upload ShipRocket File</h2>
          <input type="file" onChange={handleFileChange} />
          <Button onClick={handleUpload}>Upload File</Button>
          <br />
          <p><b className={uploadFileMsg?.status === 1 ? `text-success` : `text-danger`}>{uploadFileMsg?.message}</b></p>
          {
            !!csvRecordTotal && <>
              <p><b>All Ready Cancel:</b> {csvRecordTotal?.allreadyCanceld}</p>
              <p><b>Merge Order:</b> {csvRecordTotal?.mergeorder}</p>
              <p><b>Other Product Merge Order:</b> {csvRecordTotal?.newmergeorder}</p>
              <p><b>Mix Orders:</b> {csvRecordTotal?.nameslipSingleIdMultiPro}</p>
              <p><b>Mix Other Orders:</b> {csvRecordTotal?.mixorders}</p>
              <p><b>Name Slip:</b> {csvRecordTotal?.nameslip}</p>
              <p><b>Pen/Pencils:</b> {csvRecordTotal?.pencils}</p>
              <p><b>Other Orders:</b> {csvRecordTotal?.otherOrders}</p>
              <p><b>All Record Single Id Total:</b> {csvRecordTotal?.allRecordSingleId}</p>
            </>
          }
        </Col>
        <Col md={6}>
          <Row>
          <Col md={4}>
              <Form action={`${baseUrl}/api/margeorder/new/list`}>
                <Button type='submit' className='w-100 m-5' variant='info'
                  onClick={() => setTimeout(() => { window.location.reload(true) }, 2000)}
                >
                  <h5>
                    Merge Order Download
                  </h5>
                 Only Name Slip, Gifts Slips, Vinal Poster,
                 Pen/Pencil,Handkerchief
                </Button>
              </Form>
            </Col>
            <Col md={4}>
              <Form action={`${baseUrl}/api/double/order/list`}>
                <Button type='submit' className='w-100 m-5' variant='primary'
                  onClick={() => setTimeout(() => { window.location.reload(true) }, 2000)}
                >
                  <h5>
                  Other Products Merge Orders 
                  </h5>
                  Other Products and <b> Exclusive</b> Name Slip, Gifts Slips, Vinal Poster,
                  Pen/Pencil,Handkerchief
                </Button>
              </Form>
            </Col>
          
            <Col md={4}>
              <Form action={`${baseUrl}/api/singleid/multiproduct`}>
                <Button type='submit' className='w-100 m-5' variant='info'
                  onClick={() => setTimeout(() => { window.location.reload(true) }, 2000)}>
                  <h5>
                  Single Id To Multiple Product
                  </h5>
                  Name slip, Gifts slip, Vinal Poster, Pen/Pancil,Handkerchief, <b>Exclusive</b> Merge Orders
                </Button>
              </Form>
            </Col>
          </Row>
          
          <Row>
          <Col md={4}>
              <Form action={`${baseUrl}/api/singleid/multiproduct/other`}>
                <Button type='submit' className='w-100 m-5' variant='info'
                  onClick={() => setTimeout(() => { window.location.reload(true) }, 2000)}>
                  <h5>
                    Single Id To Multiple Product
                  </h5>
                  <b>Exclusive</b> Merge Order and Name slip, Gifts slip, Vinal Poster, Pen/Pancil,Handkerchief
                </Button>
              </Form>
            </Col>
            <Col md={4}>
              <Form action={`${baseUrl}/api/all/nameslip`}>
                <Button type='submit' className='w-100 m-5' variant='primary'
                  onClick={() => setTimeout(() => { window.location.reload(true) }, 2000)}>
                  <h5>
                    Only Name Slip Dimension
                  </h5>
                  Name slip, Gifts slip, Vinal Poster,Handkerchief
                </Button>
              </Form>
            </Col>
            <Col md={4}>
              <Form action={`${baseUrl}/api/all/penandpencil`}>
                <Button type='submit' className='w-100 m-5' variant='info'
                  onClick={() => setTimeout(() => { window.location.reload(true) }, 2000)}>
                  <h5>
                    Pen/Pancil Dimension
                  </h5>
                  Pen/Pancil
                </Button>
              </Form>
            </Col>
           
          </Row>
          <Row>
            <Col md={4}>
              <Form action={`${baseUrl}/api/all/other/order`}>
                <Button type='submit' className='w-100 m-5' variant='info'
                  onClick={() => setTimeout(() => { window.location.reload(true) }, 2000)}>
                  <h5>
                    All Other Orders
                  </h5>
                  Exclusive Merge Order, Name slip, Gifts slip, Vinal Poster and Pen/Pancil
                </Button>
              </Form>
            </Col>
            <Col md={4}>
              <Form action={`${baseUrl}/api/custom/order`}>
                <Button type='submit' className='w-100 m-5' variant='primary'
                  onClick={() => setTimeout(() => { window.location.reload(true) }, 2000)}>
                  <h5>
                    Custom Order Create
                  </h5>
                  Exp  1001 = 1001A
                  Amount and Quantitys Merge
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FileUploader;
